import React from 'react';
import Container from '../../components/Container';
import SEO from '../../components/seo';
import TemoignagePages from '../../components/PagesComponent/PageTemoignage'

const Index = () => {
  return (
    <Container>
      <SEO
        lang="fr-FR"
        title="Hairun Technology - temoignage"
        description="Hairun Technology High Level IT Development"
      />
      <TemoignagePages />
    </Container>
  );
};

export default Index;
