import React from 'react';

import classnames from 'classnames';

import styles from './style';
import ReactHtmlParser from 'react-html-parser';
import { Box, Typography } from '@material-ui/core';
import SlickSlider from '../SlickSlider';
import { PrevArrow, NextArrow } from '../SlickSlider/Arrow';
import Layout from '../Layout';

import SmartPredict from '../../../images/SmartPredict-logo.svg';
import Eliotech from '../../../images/Eliotek.png';
import ViewPay from '../../../images/viewpay/logo.png';
/*import Fanampiana from '../../../images/fanampiana/logo-fanampiana.png';
import Jobissim from '../../../images/Jobissim/logo-jobissim.png';
*/
const TemoignagesData = [
  {
    id: 1,
    title: 'SmartPredict',
    image: SmartPredict,
    name: 'Stanislas R.',
    poste: 'CTO SmartPredict',
    text:
      'J’ai été particulièrement impressionné par la capacité des équipes de HaiRun Technology à gérer des projets complexes et difficiles. En général, il faut des années pour avoir ce type de qualité chez les professionnels du développement informatique, mais c’était inné chez eux !',
  },

  {
    id: 2,
    title: 'ElioTeknology',
    image: Eliotech,
    name: 'ElioTeknology',
    poste: 'CEO elioTeknology',
    text:
      'Nous avons décidé de confier le développement de notre application à HaiRun Technology. Peu d’entreprises ont cette capacité de répondre à nos besoins en si peu de temps, avec des coûts abordables. Nous avons eu la chance de travailler avec une équipe efficace.',
  },
  {
    id: 3,
    title: 'ViewPay',
    image: ViewPay,
    name: 'Marc Leprat',
    poste: 'Co-founder & CEO',
    text:
      "Ayant confié des missions d'assistances techniques en architecture cloud AWS auprès de HaiRun Technology, nous en sommes extrêmement satisfaits. Ils nous ont prouvé leurs capacités à bien mener cette prestation, de manière très efficace. Nous les recommandons vivement pour ce genre de mission !",
  },
  /*{
    id: 3,
    title: 'Jobissim',
    image: Jobissim,
    name: 'Thomas Prévost',
    poste: 'PDG',
    text:
      'Nous avons décidé de confier le développement de notre application à HaiRun Technology. Peu d’entreprises ont cette capacité de répondre à nos besoins en si peu de temps, avec des coûts abordables. Nous avons eu la chance de travailler avec une équipe efficace.',
  },
  {
    id: 5,
    title: 'Fanampiana',
    image: Fanampiana,
    name: 'Fanampiana',
    poste: 'CEO',
    text:
      'Nous avons décidé de confier le développement de notre application à HaiRun Technology. Peu d’entreprises ont cette capacité de répondre à nos besoins en si peu de temps, avec des coûts abordables. Nous avons eu la chance de travailler avec une équipe efficace.',
  },*/
];

interface PropTypes {
  title: string;
}
const BlocMotsClientSlider = ({ title }: PropTypes) => {
  const classes = styles();
  const slickSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    prevArrow: (
      <PrevArrow classes={classnames(classes.slickArrow, classes.slickPrev)} />
    ),
    nextArrow: (
      <NextArrow classes={classnames(classes.slickArrow, classes.slickNext)} />
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box id="clients" className={classes.temoignageWrapper}>
      <Box className={classes.background} />
      <Layout>
        <Typography
          variant="h2"
          className={classnames('titreSection', classes.title)}
        >
          {ReactHtmlParser(title)}
        </Typography>
        <Typography className={classnames('textParagraphe', classes.subTitle)}>
          Plus de 20 clients utilisent notre savoir faire en matière de
          plateforme digitale pour mieux comprendre leur activité et leur
          marché.
        </Typography>
        <SlickSlider
          settings={slickSettings}
          classesnames={classes.temoignagesList}
        >
          {TemoignagesData.map(({ id, image, title, text, name, poste }) => (
            <Box key={id} className={classes.listItem}>
              <blockquote>
                <span className={classes.bqstart}>“</span>
                {text}
                <span className={classes.bqend}>”</span>
              </blockquote>
              <Box className={classes.contentInfoPersonne}>
                <Box display="flex" alignItems="center">
                  <Box className={classes.avatar}>
                    <img src={image} alt={title} />
                  </Box>
                  <Box>
                    <Typography
                      className={classnames('temoignageName', classes.name)}
                    >
                      {name}
                    </Typography>
                    <Typography
                      className={classnames(
                        'temoignageFunction',
                        classes.functions,
                      )}
                    >
                      {poste}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </SlickSlider>
      </Layout>
    </Box>
  );
};

export default BlocMotsClientSlider;
