import { makeStyles } from '@material-ui/core';

import Bg from '../../../images/element6.svg';

export default makeStyles((theme) => ({
  technologiesWrapper: {
    position: 'relative',
    color: '#fff',
    textAlign: 'center',
    width:"100%",
    paddingTop: theme.spacing(24.5),
    paddingBottom: theme.spacing(24.5),
    '@media (max-width: 1200px)': {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    '@media (max-width: 992px)': {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
    backgroundImage: `url(${Bg})`,
    backgroundPosition: '0 0',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    '@media (max-width: 1200px)': {
      backgroundImage: 'none',
    },
  },
  technologiesContainer: {
    maxWidth: 1430,
  },

  technologiesTitle: {
    fontWeight: 700,
    fontSize: '2.5rem',
  },
  technologiesDescription: {
    fontSize: '1.250rem',
  },
  technologiesList: {
    // backgroundColor: '#ffffff',
    borderRadius: 84,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(14),
    // maxWidth: 1184,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    paddingLeft: 0,
    paddingRight: 0,
    '@media (max-width: 1200px)': {
      justifyContent: 'center',
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(0),
    },
    '@media (max-width: 768px)': {
      borderRadius: 5,
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  },
  technologiesListItem: {
    width: '20%',
    '@media (max-width: 1200px)': {
      minWidth: 150,
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    '&.technologiesIconNode svg': { maxWidth: 120 },
    '&.technologiesIconReact svg': { maxWidth: 152 },
    '&.technologiesIconAngular svg': { maxWidth: 187 },
    '&.technologiesIconDrupal svg': { maxWidth: 212 },
    '&.technologiesIconSymfony svg': { maxWidth: 204 },
    '&.technologiesIconIOS svg': { maxWidth: 128 },
    '&.technologiesIconSwift svg': { maxWidth: 164 },
    '&.technologiesIconObjectiveC svg': { maxWidth: 215 },
    '&.technologiesIconReactNative svg': { maxWidth: 261 },
    '&.technologiesIconGooglePlay svg': { maxWidth: 229 },
    '&.technologiesIconAppStore svg': { maxWidth: 231 },
  },
  technologiesListItemIcon: {
    width: '100%',
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      margin: 'auto',
    },
    '& img': {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
    },
  },
}));
