import React from 'react';

import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
} from '@material-ui/core';
import Layout from '../Layout';

import classnames from 'classnames';

import styles from './style';

import ReactHtmlParser from 'react-html-parser';

interface PropTypes {
  title: string;
  description: string;
  bgcolor?: string;
  items: any;
  minWidthItem?: string;
  maxWidthItem?: string;
  marginBottomItem?: string;
  marginTopItem?: string;
}
const Techonologies = ({
  title,
  description,
  bgcolor,
  items,
  maxWidthItem,
  minWidthItem,
  marginBottomItem,
  marginTopItem,
}: PropTypes) => {
  const classes = styles();
  return (
    <Box id="technologies" className={classes.technologiesWrapper}>
      <Box className={classes.background} bgcolor={bgcolor} />

      <Layout className={classes.technologiesContainer}>
        <Box>
          <Typography
            variant="h4"
            className={classnames('titreSection', classes.technologiesTitle)}
          >
            {ReactHtmlParser(title)}
          </Typography>
          <Typography
            className={classnames(
              'textParagraphe',
              classes.technologiesDescription,
            )}
          >
            {ReactHtmlParser(description)}
          </Typography>
          <List className={classes.technologiesList}>
            {items.map(({ id, icon, title }: any) => {
              return (
                <ListItem
                  key={id}
                  className={classnames(
                    `technologies${title}`,
                    classes.technologiesListItem,
                  )}
                  // style={{
                  //   minWidth: minWidthItem,
                  //   marginTop: marginTopItem,
                  //   marginBottom: marginBottomItem,
                  // }}
                >
                  <ListItemIcon className={classes.technologiesListItemIcon}>
                    {icon}
                  </ListItemIcon>
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Layout>
    </Box>
  );
};
export default Techonologies;
