import React from 'react';

import { Card, Typography, Button, Box } from '@material-ui/core';
//import { TransitionLink as Link } from '../TransitionLink/TransitionLink';
import LinkUI from '@material-ui/core/Link';
//import IconArrowRightAlt from '../Icons/IconArrowRightAlt';

import Layout from '../Layout';

import classnames from 'classnames';

import styles from './style';

import ReactHtmlParser from 'react-html-parser';
import SlickSlider from '../SlickSlider';
import { PrevArrow, NextArrow } from '../SlickSlider/Arrow';
import ArrowRight from '@material-ui/icons/ArrowRightAlt';

import SmartPredict from '../../../images/mockup_laptop_smartpredict.png';
import Eliotech from '../../../images/mockup_mobile_and_laptop.png';
import ViewPay from '../../../images/viewpay/mockup_laptop_viewpay.png';
//import Fanampiana from '../../../images/fanampiana/mockup_laptop_fanampiana.png';
//import Jobissim from '../../../images/Jobissim/mockup_laptop_jobissim.png';

const ProjetsData = [
  {
    id: 1,
    linkDetail: '/awspartner/smartpredict',
    title: 'SmartPredict',
    image: SmartPredict,
    subTitle: 'Courte description du projet',
    description:
      'SmartPredict est un logiciel SaaS destiné aux entreprises et qui leur permet de créer et déployer des projets d’intelligence artificielle en rapport avec leurs métiers. Les usages sont multiples sur tous les secteurs : prévisions de consommation (industrie), recommandations de produits (marketing), prédictions de défaut (banque et assurance), etc. Avec leur dernier outil RetailForecastor, l’entreprise permet aux entreprises de la grande distribution de prédire leurs ventes et optimiser leurs activités en évitant la surproduction ou la sous-production.',
  },
  {
    id: 2,
    linkDetail: '/awspartner/elioteknology',
    title: 'ElioTeknology',
    image: Eliotech,
    subTitle: 'Courte description du projet',
    description:
      "ElioTeknology s'engage à concevoir et à fabriquer des chauffe-eau solaires et à exporter des chauffe-eau solaires sans réservoir et des réservoirs de chauffe-eau solaires dans le monde entier. Pour cela nous avons créer une application mobile comme interface utilisateur et une application web pour l'admnistration de l'application.",
  },
  {
    id: 3,
    linkDetail: '/awspartner/viewpay',
    title: 'ViewPay',
    image: ViewPay,
    subTitle: 'Courte description du projet',
    description:
      "Viewpay étant une entreprise qui offre des solutions technologiques pour les médias et la publicité en ligne, nous avons fourni à cette dernière une application permettant d'avoir un reporting sur les données générées par la plateforme Viewpay à travers des dashboards (administrateurs, annonceurs et éditeurs). Pour cette prestation, nous avons utilisé des services de AWS tels que CloudWatch sy EC2.",
  },
  /*{
    id: 4,
    title: 'Jobissim',
    image: Jobissim,
    subTitle: 'Courte description du projet',
    description:
      'Jobissim est une plateforme en ligne, un site internet, mettant en relation les demandeurs d’emploi et de formation avec les organismes qui en proposent. Cher.e.s demandeurs, cher.e.s recruteurs, vous pouvez dorénavant communiquer, vous rencontrer, vous entraider ! Pour cela rien de plus simple, la messagerie et la Visioconférence vous facilitent la vie.',
  },
  {
    id: 5,
    title: 'Fanampiana',
    image: Fanampiana,
    subTitle: 'Courte description du projet',
    description:
      'Fanampiana a été imaginé par son fondateur Mamy RANDRIANASOLO, d’une part dans le but de faciliter la diaspora malagasy à soutenir proches et amis à Madagascar, d’autre part promouvoir les entreprises malagasy en leur offrant une meilleure visibilité commerciale et pouvoir vendre directement leurs produits et services sur la plateforme digitale de fanampiana.com.',
  },*/
];

interface PropTypes {
  titles: string;
}

const isBrowser = typeof window !== 'undefined';

const BlocHeaderTemoignageSlider = ({ titles }: PropTypes) => {
  const classes = styles();
  const slickSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    prevArrow: (
      <PrevArrow classes={classnames(classes.slickArrow, classes.slickPrev)} />
    ),
    nextArrow: (
      <NextArrow classes={classnames(classes.slickArrow, classes.slickNext)} />
    ),
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Box id="headerBlocTemoignage">
      <Box id="cardOffers" className={classes.cardOfferSection}>
        <Layout className={classes.cardOffersContainer}>
          <Typography
            variant="h2"
            className={classnames('titreSection', classes.title)}
          >
              {ReactHtmlParser(titles)}
          </Typography>
          <Box className={classes.cardOfferWrapper}>
            <SlickSlider settings={slickSettings}>
              {ProjetsData.map(
                ({ id, linkDetail, image, title, subTitle, description }) => (
                  <Card key={id} className={classnames(classes.cardRoot)}>
                    {/* <img
                      className={classes.cardMedia}
                      src={image}
                      alt="card media"
                    /> */}
                    <Box className={classes.card}>
                      <Typography component="h1" className={classes.cardTitle}>
                        <LinkUI href={linkDetail} underline="none">
                          {ReactHtmlParser(title)}
                        </LinkUI>
                        <br />
                        
                      </Typography>
                      <Button
                        variant="outlined"
                        color="primary"
                        endIcon={<ArrowRight color="inherit" fontSize="small" />}
                        className="classicButton"
                        href={linkDetail}
                      >
                        Voir détail
                      </Button>
                      {/* <Typography component="h2" className={classes.subTitle}>
                        {subTitle}
                      </Typography> */}
                      {/* <Box className={classes.rollover}>
                        <Typography
                          className={classnames(
                            'textParagraph',
                            classes.cardText,
                          )}
                        >
                          {description}
                        </Typography>

                        <Box mt={2} className={classes.contentBtn}>
                          <Typography>
                            Voulez vous en savoir plus sur ce projet ?
                          </Typography>
                          <Link
                            to="/contact"
                            state={{
                              prevUrl: isBrowser
                                ? window.location.pathname
                                : '',
                            }}
                          >
                            <Button
                              className={classnames(
                                'classicButton',
                                classes.btnDemarrer,
                              )}
                              variant="outlined"
                              endIcon={
                                <IconArrowRightAlt viewBox="0 0 24 24" />
                              }
                            >
                              Contactez-nous
                            </Button>
                          </Link>
                        </Box>
                      </Box> */}
                    </Box>
                  </Card>
                ),
              )}
            </SlickSlider>
          </Box>
        </Layout>
      </Box>
    </Box>
  );
};
export default BlocHeaderTemoignageSlider;
