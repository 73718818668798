import React from 'react';
import styles from './style';
import Layout from '../Layout';
import { Typography, Box } from '@material-ui/core';
import classnames from 'classnames';
import imgIllustration from '../../../images/partners-top-image-aws.png';
import ReactHtmlParser from 'react-html-parser';

interface PropTypes {
  serviceTitle: string;
}
const paragraphes = [
  'Parmis notre meilleure réussite, nous sommes heureux de vous partager nos témoignages clients et cette relation de confiance, qui permet à toute l’équipe de <span>Hairun Technology</span> de créer, développer et mettre en place les beaux projets qu’ils ont acceptés et que vous accepterez de nous confier. Nous les en remercions !',
  '',
];
{
  /*const paragraphes = [
  "<span>Témoignages</span> de <span>quelques clients</span> depuis plus de 1 ans en tant que principal fournisseur de solutions cloud-native. Nous aidons les entreprises à transformer leur façon de travailler en leur donnant les moyens d'évoluer vers AWS.",
  '',
];*/
}
const BlocTitlePartnerAws = ({ serviceTitle }: PropTypes) => {
  const classes = styles();

  return (
    <Box bgcolor="#FFF" className={classes.offersSection}>
      <Box className={classes.background} />
      <Box className={classes.offersPartner} id="nos-services">
        <Layout className={classes.offersContainer}>
          <Box
            display="flex"
            flexDirection="column"
            className={classes.offersText}
          >
            <Typography
              variant="h2"
              className={classnames('titreSection', classes.offersTitle)}
            >
              {ReactHtmlParser(serviceTitle)}
            </Typography>

            {paragraphes.map((item, index) => (
              <Typography
                key={index}
                className={classnames(
                  'textParagraphe',
                  classes.offersParagraphe,
                  index % 2 === 0
                    ? classes.offersParagrapheOdd
                    : classes.offersParagrapheEven,
                )}
              >
                {ReactHtmlParser(item)}
              </Typography>
            ))}
          </Box>
          <Box className={classes.offersIllustrationWrapper}>
            <img src={imgIllustration} alt="hero" />
          </Box>
        </Layout>
      </Box>
    </Box>
  );
};

export default BlocTitlePartnerAws;
