import React, { useEffect, useState } from 'react';
import styles from './style';

import { Box } from '@material-ui/core';

//import img1 from '../../../images/mockup_mobile_and_laptop.png';
//import gallery1 from '../../../images/eliotech/mockup_laptop_mlti_eliotech1.png';
//import gallery2 from '../../../images/eliotech/mockup_laptop_mlti_eliotech2.png';
//import gallery3 from '../../../images/eliotech/mockup_mobile_mlti_eliotech3.png';

//import IconAws from '../../Common/Icons/IconAws';
//import IconNode from '../../Common/Icons/IconNode';
//import IconReactJS from '../../Common/Icons/IconReact';
//import IconGoogleCloud from '../../Common/Icons/IconGoogleCloud';
//import IconReactNative from '../../Common/Icons/IconReactNative';
import TitlePartnerAws from '../../Common/BlocTitlePartnerAws';

import ScrollTo from '../../Common/ScrollTo';

import ContactForm from '../../Common/ContactForm';
import ImgAmazonS3 from '../../../images/amazonService/amazonS3.png';
import ImgAmazonRds from '../../../images/amazonService/amazon_rds.png';
import ImgAmazonDynamodb from '../../../images/amazonService/Amazon-dynamodb.png';
import ImgAmazonRedShift from '../../../images/amazonService/amazon_redshift.png';
import ImgAmazonKinesis from '../../../images/amazonService/amazon_kinesis.png';
import ImgAmazonEmr from '../../../images/amazonService/amazon-emr.png';

import Techonologies from '../../Common/Technologies';
import BlocHeaderTemoignageSlider from '../../Common/BlocHeaderTemoignageSlider';
// BlocGallery from '../../Common/BlocGalleryTemoignage';
import MotClientSlider from '../../Common/BlocMotsClientSlider';

const TechnologiesItem2 = [
  {
    id: 1,
    icon: <img src={ImgAmazonS3} width="120" height="120" alt="images amazonS3" />,
    title: 'Amazon',
  },
  {
    id: 2,
    icon: <img src={ImgAmazonRds} width="150" height="83" alt="AmazonRds" />,
    title: 'Amazon',
  },
  {
    id: 3,
    icon: <img src={ImgAmazonDynamodb} width="145" height="86" alt="AmazonDynamo" />,
    title: 'Amazon',
  },
  {
    id: 4,
    icon: <img src={ImgAmazonRedShift} width="145" height="82" alt="amazon Shift" />,
    title: 'Amazon',
  },
  {
    id: 5,
    icon: <img src={ImgAmazonKinesis} width="145" height="78" alt="Kinesis" />,
    title: 'Amazon',
  },
  {
    id: 6,
    icon: <img src={ImgAmazonEmr} width="125" height="76" alt="amazonEmr" />,
    title: 'Amazon',
  },
];

{
  /*const GalleryItems = [
  {
    id: 1,
    images: gallery1,
  },
  {
    id: 2,
    images: gallery2,
  },
  {
    id: 3,
    images: gallery3,
  },
];*/
}
const PageTemoignage = () => {
  const classes = styles();
  const [showScrollTo, setShowScrollTo] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    setShowScrollTo(offset > 1000);
  };
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const domaine_ext = url.includes(".fr");
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <Box id="elioTeknologyPage" className={classes.cardOfferSection}>
      {showScrollTo ? (
        <ScrollTo element="/awspartner/#elioTeknologyPage" />
      ) : null}
      <TitlePartnerAws serviceTitle="<span>Témoignages</span><br/> de quelques <span>clients</span>" />
      <BlocHeaderTemoignageSlider titles="Quelques exemples de projets :" />
      {/*<BlocGallery
        title="<span>Quelques </span>images"
        description="Découvrez les actus de HaiRun Technology, des astuces et pleins d'autres items autour de la technologie et du digital."
        items={GalleryItems}
      />*/}
      <Box className={classes.blocTechno}>
        <Techonologies
          bgcolor="#333333"
          title="Amazon Web Services"
          description="Amazon Web Services offre une gamme complète de produits mondiaux basés sur le cloud, notamment : <br/> Computing, Storage, Databases, Analytics, Networking, Mobile, Developer Tools, Management Tools, IoT, Security, Enterprise Applications and more. <br/> <br/>Disponibles à la demande, accessibles en quelques secondes, avec une tarification à l'usage, les produits AWS permettent aux entreprises, aux start-ups, aux petites et moyennes entreprises et aux agences du secteur public d'accéder à tous les éléments de base dont ils ont besoin pour répondre rapidement à des exigences commerciales en constante évolution."
          items={TechnologiesItem2}
          minWidthItem="120px"
          marginTopItem="24px"
          marginBottomItem="24px"
        />
      </Box>
      <MotClientSlider title="<span>Les mots </span>du client" />
      {!domaine_ext ?
        <ContactForm
          title="<span>Nous restons à votre</span> disposition"
          description="Pour toute question, n'hésitez pas à nous contacter."
          mailto="contact@hairun-technology.com"
          formTitle="Contactez-<span>nous</span>"
        />
        :
        <ContactForm
          title="<span>Nous restons à votre</span> disposition"
          description="Pour toute question, n'hésitez pas à nous contacter."
          mailto="contact@hairun-technology.fr"
          formTitle="Contactez-<span>nous</span>"
        />}
    </Box>
  );
};

export default PageTemoignage;
