import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  cardOfferSection: {
    position: 'relative',
    '& .technologiesIconAws': {
      maxWidth: 90,
      width: 90,
    },
    '& .technologiesIconDigitalOcean': {
      maxWidth: 100,
    },
    '& .technologiesIconHosteur': {
      maxWidth: 150,
    },
    '& .technologiesIconGoogleCloud': {
      maxWidth: 225,
      width: 225,
    },

    '& .technologiesIconAzure': {
      maxWidth: 150,
    },
    '& .MuiList-root': {
      justifyContent: 'space-around',
    },
    '& .textParagraphe': {
      width: '100%',
      maxWidth: 1024,
      margin: '0 auto',
    },
  },
  blocTechno: {
    '& .technologiesAmazon': {
      width: 'auto',
      paddingTop: 12,
      paddingBottom: 0,
    },
    '& img': {
      width: 'auto!important',
      padding: 6,
      objectFit: 'contain',
    },
  },
}));
